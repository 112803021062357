@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: 0%;
  overflow: hidden;
  font-family: Merriweather;
}

/* Navigation */

.nav-wrapper{
  position: fixed;
  background-color: black;
  height: 4em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 1;
}

.nav-item-wrapper{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.nav-item-wrapper > div {
  margin-left: -10%;
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
  font-weight: bolder;
}

.nav-item-wrapper > div:hover{
  color: rgb(175,238,238);
  cursor: pointer;
}

.nav-logo-img{
  height: 4em;
}

.nav-logo-img:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  cursor: pointer;
  transition: ease-in-out .5s;
}

.hamburger-menu{
  display: none;
}

/* carousel */

.top-carousel-wrapper {
  padding-top: 6%;
  padding-bottom: 2%;
  height: 450px;
  background: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.carousel-image-wrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: hidden;
  width: 90%;
}

.image {
  width: 90vw;
  object-fit: scale-down;
}

.arrow-overall-wrapper{
  width: 100vw;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.arrow-sub-wrapper{
  background: rgba(0, 0, 0, 0.404);
  height: 100%;
  width: 6vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: black;
}

.arrow{
  height: 10vh;
  width: 4.0vw;
  color: rgba(255, 255, 255, 0.925);
  cursor: pointer;
}

.arrow:hover{
  color:  rgb(175,238,238);
  transition: ease-in-out .4s;
}

/* welcome widget */

.welcome-columns {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 400px;
}

.welcome-logo-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 40%;
}

.welcome-logo {
  height: 100%;
}


.welcome-logo:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  cursor: pointer;
  transition: ease-in-out .5s;
}


.welcome-text{
  height: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 10%;
  font-weight: bold;
  margin-right: 10%;
  text-align: justify;
}

.welcome-text > h1{
  color: black;
}

/* .email-input-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5em;
  position: relative;
  margin-top: 2%;
}

.email-input {
width: 68%;
height: 1.5em;
border: solid;
font-size: 1.5em;
}

.submit-email{
height: 2.5em;
display: flex;
justify-content: center;
width: 25%;
border: black;
background: rgb(175,238,238);
align-items: center;
position:absolute;
right: 5%;
border: black;
border-style: solid;
border-width: 2px;
}

.submit-email:hover {
  background: rgb(45, 209, 209);
  cursor: pointer;
} */

/* gallery */

.super-gallery-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.gallery-wrapper {
  background: white;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  padding-top: 1%; 
}

.img-wrapper{
 width: 90%;
 height: 93%;
 padding: 3%;
 background: black;
 margin: auto;
 transition: ease-out .5s;
}

.gallery-image{
  width: 100%;
  height: 100%;
  object-fit:cover;
  object-position: top;

}

.img-wrapper:hover {
  padding: 3.5%;
  transition: ease-in-out .5s;
}

/* ebook */

.ebook-wrapper{
  height: 350px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.ebook-logo-wrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.ebook-link-logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

}
.ebook-logo {
  height: auto;
  width: 45%;
  transition:  ease-in .5s;
}

.ebook-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: justify;
  text-justify: inter-word;
  font-weight: bold;
  margin-right: 10%;
}

.ebook-logo:hover {
  width: 46%;
  transition: ease-in .5s;
}

/* contact form */

.contact-wrapper{
  height: 350px;
  background: black;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact-link{
  color:  rgb(175,238,238);
}

.blog-wrapper {
  height: 500%;
  width: 100%;
  background: black;
  position: absolute;
  color: white;
}

.blog-post-wrapper {
  width: 90%;
  margin: auto;
}


@media only screen and (max-width: 700px) {

  .nav-wrapper{
    height: 4em;
    }

  .nav-item-wrapper{
    background: rgb(0, 0, 0);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 100vw;
    height: 100vh;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    z-index: 1;
  }

  .nav-wrapper{
    width: 100vw;
    transition: ease-out .5s;
  }

  .nav-wrapper.active {
    height: 100vh;
    transition: ease-out .5s;
  }


  .nav-item-wrapper.active {
    height: 100vh;
    transition: ease-out .5s;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  
  .hamburger-wrapper{
    height: 65px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 0%;
    right: 0%;
    top:0%;
  }

  .hamburger-menu {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: black;
    height: 2.5em;
    width: 2.5em;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-right: .5em;
    cursor: pointer;

  }

  .hamburger-menu > span {
    width: 2em;
    height: 3px;
    background: white;
    transition: ease-in .2s;
  }

.hamburger-menu:hover span {
    background: rgb(175,238,238);
  }

.line1.active{
    -webkit-transform: translateY(12px) rotate(45deg);
            transform: translateY(12px) rotate(45deg);
    transition: ease-in .5s;
}

.line2.active{
    opacity: 0%;
    transition: ease-in-out .5s;
}

.line3.active{
    -webkit-transform: translateY(-9px) rotate(-45deg) ;
            transform: translateY(-9px) rotate(-45deg) ;
    transition: ease-in .5s;
}

.top-carousel-wrapper {
  height: 300px;
  padding-top: 12%;
  margin-bottom: -2%;
}

.arrow-sub-wrapper{
  width: 8vw;
}

/* welcome */

.welcome-columns {
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  height: 700px;

}

.welcome-logo-wrapper{
  height: 350px;
  -webkit-align-items: center;
          align-items: center;
}

.welcome-logo {
  height: 100%;
  margin-top: 4%;
}

.welcome-text{
  height: 100%;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: -5%;
  margin-right: 0%;
  text-align: center;
}

.welcome-text > * {
  margin: auto;
}

/* .email-input-wrapper {
  margin-top: 4%;
  width: 90%;
} */

.gallery-wrapper {
  grid-template-columns: 100%;
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
}

.ebook-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: 100%;
  padding-bottom: 2%;
}

.ebook-text{
  padding-right: 5%;
  padding-left: 5%;
  margin-top: -5%;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  margin-right: 0%;
}

.ebook-logo {
  width: 45%;
  height: auto;
  margin-top: 5%;
  transition: ease-in .5s;
  margin-bottom: 5%;
  transition: none;
}

.ebook-logo:hover {
  transition: none;
}

.contact-wrapper > *{
font-size: 1.35em;
text-align: center;
}

}

@media (orientation : landscape) {

.ebook-wrapper{
  height: 500px;
} 

.welcome-columns {
  height: 450px;
}

@media only screen and (min-width: 1400px) {

.ebook-logo{
  width: 32%;
} 

.ebook-logo:hover{
  width: 34%;
} 

}
}
